<template>
  <div>
    <component :is="appMode"></component>
  </div>
</template>
<script>
export default {
  components: {
    super: () => import("./super/Referral"),
    staff: () => import("./personal/Personal"),
  },
};
</script>
